import type { Action } from '../types'
import { useRecaptchaScript } from './useRecaptchaScript'

export function useRecaptcha() {
  const config = useRuntimeConfig()
  const apiKey = config.public.recaptchaKey

  const recaptchaToken = ref('')
  const isLoadingRecaptcha = ref(false)
  const recaptchaQueryParam = computed(() => ({
    recaptchaToken: recaptchaToken.value,
  }))

  const { onLoaded } = useRecaptchaScript()

  async function getToken(action: Action) {
    try {
      isLoadingRecaptcha.value = true
      const tokenPromise = new Promise<string>((resolve) => {
        onLoaded(({ ready, execute }) => {
          ready(async () => {
            const result = await execute(apiKey, {
              action,
            })
            resolve(result)
          })
        })
      })

      recaptchaToken.value = await tokenPromise
    } catch (error) {
      console.error('loadRecaptchaToken error', error)
    } finally {
      isLoadingRecaptcha.value = false
    }
  }

  return {
    getToken,
    recaptchaToken,
    isLoadingRecaptcha,
    recaptchaQueryParam,
  }
}
